import { FireIcon } from "@heroicons/react/16/solid"
import { AllMercurialInfo } from "../../reducers/mercurialReducer"
import { StoreReducerState } from "../../reducers/storeReducer"
import { MercurialeInfo } from "../../utils/__generated__/graphql"
import ShelfFloorSize, { ShelfSizeInput } from "./ShelfFloorSize"
import BackInventory from "./BackInventory"

import FloorInventory from "./FloorInventory"
import OrderInventory from "./OrderInventory"
import { EquivalentInventory } from "./BaseInventory"
import Reload from "../../assets/Reload"
import { Square3Stack3DIcon } from "@heroicons/react/24/solid"
import { UserInterfaceReducerState } from "../../reducers/userInterfaceReducer"
import { LastPerformingTable } from "../../ui/LastPerformingTable"
import { Badges } from "../../ui/Badges"
import { ReferenceDetails } from "../../ui/ReferenceDetails"
import { Fragment, useMemo, useState } from "react"
import { Modal } from "../../ui/Modal"
import { Img } from "react-image"
import { useWindowSize } from "../../hooks/useWindowSize"
import LocalFlagToggle from "../button/LoggleFlagButton"
import { getQuarterValue } from "../../utils/getQuarterValue"
import { Delivery } from "../../ui/Delivery"
import { twMerge } from "tailwind-merge"

export interface RowProps {
  index: number
  bestSellers: MercurialeInfo[]
  updateInventory: (
    value: string,
    selectedInventory?: UserInterfaceReducerState["selectedInventory"],
  ) => Promise<void>
  displayShelfFloorSize: boolean
  storeSettings: StoreReducerState["storeSettings"]
  storeSuppliers: StoreReducerState["storeSuppliers"]
  storeCurrency: StoreReducerState["storeCurrency"]
  companyId: StoreReducerState["companyId"]
  storeId: StoreReducerState["storeId"]
  references: Partial<AllMercurialInfo>[]
  isOnline: boolean
  selectedInventory: UserInterfaceReducerState["selectedInventory"]
}

const imageLoader = (
  <div className="w-8 h-8 md:w-11 md:h-11 rounded-full bg-gray-300 animate-pulse" />
)

export function InventoryRow({
  bestSellers,
  storeSettings,
  updateInventory,
  displayShelfFloorSize,
  storeSuppliers,
  storeCurrency,
  companyId,
  isOnline,
  storeId,
  references,
  selectedInventory,
}: RowProps) {
  const { isLG, isMD } = useWindowSize()
  const [isOpen, setIsOpen] = useState(false)

  const orderableReferences = useMemo(() => {
    return references.filter((reference) => {
      const isSoldButNonActive = reference.sold_but_not_active ?? false
      return (
        reference.active !== false &&
        typeof reference.mercuriale_reception_date === "string" &&
        !isSoldButNonActive
      )
    })
  }, [references])

  const notOrderableReferences = useMemo(() => {
    return references.filter(
      (reference) =>
        !orderableReferences.some(
          (orderableReference) =>
            orderableReference.mercuriale_id === reference.mercuriale_id,
        ),
    )
  }, [orderableReferences, references])

  const sortedReferences = useMemo(() => {
    // Sort orderable references
    const sortedOrderableReferences = [...orderableReferences].sort((a, b) => {
      if (
        (a.quantity_predicted_array ?? []).length >
        (b.quantity_predicted_array ?? []).length
      ) {
        return -1
      }
      return 1
    })

    // Add not orderable references at the end
    return [...sortedOrderableReferences, ...notOrderableReferences]
  }, [notOrderableReferences, orderableReferences])

  const firstRow = sortedReferences[0]
  const isBestSeller = bestSellers.some(
    (bestSeller) => bestSeller.mercuriale_id === firstRow?.mercuriale_id,
  )

  const backInventoryEquivalent =
    storeSettings?.use_kg_pce === true
      ? (firstRow.back_inventory_qty ?? 0)
      : (firstRow.back_inventory_qty ?? 0) * (firstRow.colisage ?? 1)
  const floorInventoryEquivalent =
    storeSettings?.use_kg_pce === true
      ? (firstRow.floor_inventory_qty ?? 0)
      : (firstRow.floor_inventory_qty ?? 0) * (firstRow.colisage ?? 1)
  const shelfFloorSizeEquivalent =
    storeSettings?.use_kg_pce === true
      ? (firstRow.shelf_floor_size ?? 0)
      : (firstRow.shelf_floor_size ?? 0) * (firstRow.colisage ?? 1)

  const isActive =
    (firstRow.active ?? true) ||
    firstRow.sold_but_not_active === true ||
    firstRow.mercuriale_id === selectedInventory?.mercurialeId

  const bestMargin = useMemo(() => {
    if (sortedReferences.length <= 1) {
      return null
    }
    const margins = sortedReferences.map((reference) => {
      if (
        typeof reference.pa === "number" &&
        reference.pa > 0 &&
        typeof reference.pv === "number" &&
        reference.pv > 0
      ) {
        return (reference.pv - reference.pa) / reference.pv
      }
      return -Infinity
    })

    const uniqueMargins = [...new Set(margins)]
    if (uniqueMargins.length === 1) {
      return null
    }

    return Math.max(...margins)
  }, [sortedReferences])

  const itemHasPromotion = useMemo(() => {
    return references.some((reference) => reference.promotion === true)
  }, [references])

  const itemIsNew = useMemo(() => {
    return references.some((reference) => reference.new_reference === true)
  }, [references])

  return (
    <>
      <Modal
        title={firstRow?.mercuriale_name}
        open={isOpen}
        onClose={() => setIsOpen(false)}
      >
        <Badges
          expanded
          storeSuppliers={storeSuppliers}
          data={firstRow}
          isBestSeller={isBestSeller}
        />
        {isOnline && typeof firstRow.photo_id === "string" && (
          <Img
            src={`https://storage.googleapis.com/references_images/${companyId}/${firstRow?.photo_id}.jpeg`}
            className="rounded"
          />
        )}
        <LastPerformingTable
          unit={firstRow?.unit}
          colisage={firstRow?.colisage}
          inventoryHistoricalField={firstRow?.inventory_historical_field}
          saleHistoricalQuantities={firstRow?.sale_historical_quantities}
          deliveryHistoricalQuantities={
            firstRow?.delivery_historical_quantities
          }
          orderExpectedReceptionDate={firstRow?.order_expected_reception_date}
          orderNextExpectedReceptionDate={
            firstRow?.order_next_expected_reception_date
          }
          viewSalesFirst={storeSettings?.view_sales_first ?? false}
        />
      </Modal>
      <div className={`relative flex ${!isActive ? "opacity-20" : ""}`}>
        <div
          className={twMerge(
            "bg-white shadow rounded px-2 pt-2 pb-4 lg:pb-3 flex-1 z-10 flex flex-col gap-1",
            itemIsNew && "shadow shadow-yellow-300 border-2 border-yellow-600 ",
            itemHasPromotion &&
              "shadow shadow-cyan-600 border-2 border-cyan-900 ",
          )}
        >
          {sortedReferences.map((reference, i) => {
            const isBackInventoryInvalid =
              (reference?.back_inventory_qty ?? 0) < 0
            const isFloorInventoryInvalid =
              (reference?.floor_inventory_qty ?? 0) < 0
            const isOrderDisabled =
              isBackInventoryInvalid ||
              isFloorInventoryInvalid ||
              notOrderableReferences.some(
                (notOrderableReference) =>
                  notOrderableReference.mercuriale_id ===
                  reference.mercuriale_id,
              ) ||
              reference.stock_to_verify_flag === true
            const shelfFloorSizeQuarterValue = getQuarterValue(
              reference.shelf_floor_size ?? 0,
              "size-3",
            )

            return (
              <Fragment key={i}>
                <div className="flex gap-0 lg:gap-4 items-center justify-between">
                  <div className="flex flex-1 items-center gap-2">
                    {isBestSeller && i === 0 && (
                      <FireIcon className="w-4 h-4 text-red-700" />
                    )}

                    {i > 0 && (
                      <Square3Stack3DIcon className="w-4 h-4 text-neutral-500" />
                    )}
                    <div className="flex flex-1 overflow-hidden w-0">
                      <h2
                        className={`${i === 0 ? "font-black text-[#323232]" : " py-3 text-xs  font-bold text-neutral-500"} truncate`}
                      >
                        {i > 0 && (
                          <>
                            {i + 1}
                            <sup>ème</sup>
                          </>
                        )}{" "}
                        {reference?.mercuriale_name}
                      </h2>
                    </div>
                  </div>
                  <div className="flex gap-2 pl-1">
                    {storeSettings?.separate_floor_inventory === true &&
                      i === 0 && (
                        <ShelfSizeInput
                          data={firstRow}
                          updateInventory={updateInventory}
                          className="hidden lg:flex"
                        />
                      )}
                    <div
                      className={`hidden lg:block lg:min-w-[73px] ${i > 0 ? "mb-1" : ""}`}
                    >
                      {(typeof reference?.order_expected_reception_date ===
                        "string" ||
                        typeof reference?.mercuriale_reception_date ===
                          "string") && (
                        <Delivery
                          date={
                            reference?.order_expected_reception_date ??
                            reference?.mercuriale_reception_date ??
                            ""
                          }
                        />
                      )}
                    </div>
                  </div>
                  <div className="lg:hidden">
                    {storeSettings?.view_local && (
                      <LocalFlagToggle firstRow={firstRow} storeId={storeId} />
                    )}
                  </div>
                </div>
                <Badges
                  data={reference}
                  storeSuppliers={storeSuppliers}
                  isBestSeller={isBestSeller}
                  hideBreakageLabel={i > 0}
                  hide={!isLG}
                />
                <div className="flex flex-col gap-4">
                  <div className="flex flex-col lg:flex-row gap-2 lg:gap-2">
                    <div
                      className={`flex-1 flex flex-col gap-2 ${sortedReferences.length > 1 ? "lg:border-l-2 lg:border-neutral-500 lg:ml-[7px] lg:pl-4" : ""}`}
                    >
                      <div className="flex gap-2 items-center">
                        {isOnline &&
                          typeof firstRow?.photo_id === "string" &&
                          i === 0 && (
                            <Img
                              src={`https://storage.googleapis.com/references_images/${companyId}/${firstRow?.photo_id}.jpeg`}
                              className="w-12 lg:w-9 h-16 lg:h-11 rounded object-cover cursor-pointer"
                              loader={imageLoader}
                              onClick={() => setIsOpen(true)}
                            />
                          )}
                        <div className="flex flex-col gap-1 overflow-x-auto w-full">
                          <Badges
                            data={reference}
                            storeSuppliers={storeSuppliers}
                            isBestSeller={isBestSeller}
                            hideBreakageLabel={i > 0}
                            hide={isLG}
                          />
                          <ReferenceDetails
                            bestMargin={bestMargin}
                            data={reference}
                            storeCurrency={storeCurrency}
                            storeSettings={storeSettings}
                          />
                        </div>
                      </div>
                      {i === 0 && (
                        <div className="flex justify-center lg:justify-start w-full">
                          <LastPerformingTable
                            unit={reference?.unit}
                            colisage={reference?.colisage}
                            saleHistoricalQuantities={
                              reference?.sale_historical_quantities
                            }
                            inventoryHistoricalField={
                              reference?.inventory_historical_field
                            }
                            deliveryHistoricalQuantities={
                              reference?.delivery_historical_quantities
                            }
                            orderExpectedReceptionDate={
                              reference?.order_expected_reception_date ??
                              reference.mercuriale_reception_date
                            }
                            orderNextExpectedReceptionDate={
                              reference?.order_next_expected_reception_date
                            }
                            viewSalesFirst={
                              storeSettings?.view_sales_first ?? true
                            }
                          />
                        </div>
                      )}
                    </div>
                    <div
                      className={`self-center lg:self-end grid ${displayShelfFloorSize ? "grid-cols-[1fr] lg:grid-cols-[auto]" : "grid-cols-[1fr,1fr,1fr] lg:grid-cols-[auto,auto,auto]"} gap-y-1 lg:gap-y-4 gap-x-1 md:gap-x-4 lg:gap-x-0 items-start lg:items-end justify-between lg:justify-start`}
                    >
                      {!displayShelfFloorSize ? (
                        <>
                          <p className="inline lg:hidden text-xs text-zinc-500 font-bold text-center">
                            {/* TODO: Remove this condition when refactoring desktop input */}
                            {isMD ? (
                              <>
                                {storeSettings?.separate_floor_inventory ===
                                true
                                  ? "RÉSERVE"
                                  : "STOCK"}
                                {reference.stock_to_verify_flag ? (
                                  <span className="ml-1 truncate px-1 py-0.2 bg-red-500 rounded-sm text-custom-white text-xxs uppercase font-bold">
                                    À VÉRIFIER
                                  </span>
                                ) : reference.stock_too_high_flag ? (
                                  <span className="ml-1 truncate px-1 py-0.2 bg-custom-orange rounded-sm text-custom-white text-xxs uppercase font-bold">
                                    {storeSettings?.separate_floor_inventory ===
                                    true
                                      ? "ÉLEVÉE"
                                      : "ÉLEVÉ"}
                                  </span>
                                ) : (
                                  reference.stock_too_low_flag && (
                                    <span className="ml-1 truncate px-1 py-0.2 bg-custom-yellow rounded-sm text-custom-white text-xxs uppercase font-bold">
                                      FAIBLE
                                    </span>
                                  )
                                )}
                              </>
                            ) : (
                              "RÉSERVE"
                            )}
                          </p>
                          <p className="lg:hidden text-xs text-zinc-500 font-bold text-center flex justify-center items-center">
                            {/* TODO: Remove this condition when refactoring desktop input */}
                            {isMD ? (
                              storeSettings?.separate_floor_inventory ===
                              true ? (
                                <>
                                  RAYON
                                  <span className="ml-2 px-2 py-1 bg-neutral-700 text-white text-xxs rounded-full flex items-center gap-1">
                                    {
                                      getQuarterValue(
                                        reference.shelf_floor_size ?? undefined,
                                      ).value
                                    }
                                    {
                                      getQuarterValue(
                                        reference.shelf_floor_size ?? undefined,
                                        "w-4 h-4",
                                      ).icon
                                    }
                                  </span>
                                </>
                              ) : (
                                "LINÉAIRE"
                              )
                            ) : (
                              <>
                                CAPA.RAYON
                                <span className="ml-1 bg-gray-900 text-white rounded-full h-4 px-1 text-xxs flex justify-center items-center">
                                  {((shelfFloorSizeQuarterValue.value ?? 0) >
                                    0 ||
                                    shelfFloorSizeQuarterValue.icon ===
                                      undefined) &&
                                    shelfFloorSizeQuarterValue.value}
                                  {shelfFloorSizeQuarterValue.icon}
                                </span>
                              </>
                            )}
                          </p>
                          <p className="inline lg:hidden text-xs text-zinc-500 font-bold text-center">
                            COMMANDE
                            {reference.is_precommande === true && (
                              <span className="ml-1 truncate px-1 py-0.2 bg-cyan-700 rounded-sm text-custom-white text-xxs uppercase font-bold">
                                PRÉCO
                              </span>
                            )}
                          </p>
                          <BackInventory
                            isFirstRow={i === 0}
                            stock_too_high_flag={reference.stock_too_high_flag}
                            stock_too_low_flag={reference.stock_too_low_flag}
                            stock_to_verify_flag={
                              reference.stock_to_verify_flag
                            }
                            mercurialeId={reference.mercuriale_id!}
                            unit={reference.unit ?? ""}
                            waitedQuantityOrdered={
                              reference.waited_quantity_ordered ?? 0
                            }
                            promoWaitedQuantityOrdered={
                              reference.promo_waited_quantity_ordered ?? 0
                            }
                            value={
                              i > 0
                                ? backInventoryEquivalent /
                                  (reference.colisage ?? 1)
                                : (reference.back_inventory_qty ?? 0)
                            }
                            floorInventoryQuantity={
                              i > 0
                                ? floorInventoryEquivalent /
                                  (reference.colisage ?? 1)
                                : (reference.floor_inventory_qty ?? 0)
                            }
                            updateInventory={updateInventory}
                          />
                          {/* TODO: Remove this condition when refactoring desktop input */}
                          {isMD ? (
                            storeSettings?.separate_floor_inventory === true ? (
                              <FloorInventory
                                isFirstRow={i === 0}
                                mercurialeId={reference.mercuriale_id!}
                                unit={reference.unit ?? ""}
                                value={
                                  i > 0
                                    ? floorInventoryEquivalent /
                                      (reference.colisage ?? 1)
                                    : (reference.floor_inventory_qty ?? 0)
                                }
                                updateInventory={updateInventory}
                                className="lg:px-4"
                              />
                            ) : (
                              <ShelfFloorSize
                                mercurialeId={reference.mercuriale_id!}
                                unit={reference.unit ?? ""}
                                value={
                                  i > 0
                                    ? shelfFloorSizeEquivalent /
                                      (reference.colisage ?? 1)
                                    : (reference.shelf_floor_size ?? 0)
                                }
                                updateInventory={updateInventory}
                                minimal
                                className="lg:px-4"
                                isNewReference={false}
                                floorInventoryQuantity={0}
                              />
                            )
                          ) : (
                            <ShelfFloorSize
                              mercurialeId={reference.mercuriale_id!}
                              unit={reference.unit ?? ""}
                              isNewReference={reference.new_reference ?? false}
                              value={
                                i > 0
                                  ? shelfFloorSizeEquivalent /
                                    (reference.colisage ?? 1)
                                  : (reference.shelf_floor_size ?? undefined)
                              }
                              floorInventoryQuantity={
                                i > 0
                                  ? floorInventoryEquivalent /
                                    (reference.colisage ?? 1)
                                  : (reference.floor_inventory_qty ?? 0)
                              }
                              updateInventory={updateInventory}
                              minimal
                              className="lg:px-4"
                            />
                          )}
                          <div className="relative">
                            <OrderInventory
                              isFirstRow={i === 0}
                              prediction_uncertain={
                                reference.prediction_uncertain
                              }
                              mercurialeId={reference.mercuriale_id!}
                              unit={reference.unit ?? ""}
                              value={reference?.quantity_actual ?? 0}
                              updateInventory={updateInventory}
                              disabled={isOrderDisabled}
                              isPrecommande={reference.is_precommande ?? false}
                              waitedQuantityOrdered={
                                reference.waited_quantity_ordered ?? 0
                              }
                              promoWaitedQuantityOrdered={
                                reference.promo_waited_quantity_ordered ?? 0
                              }
                            />
                            <div className="hidden lg:flex h-full absolute left-full top-0 justify-center items-center">
                              <Reload
                                data={reference}
                                disabled={isOrderDisabled || !isOnline}
                              />
                            </div>
                          </div>
                          {i === 0 && (
                            <EquivalentInventory
                              value={firstRow.back_inventory_qty ?? 0}
                              colisage={firstRow.colisage ?? 1}
                              unit={firstRow.unit ?? ""}
                              useKgPce={storeSettings?.use_kg_pce === true}
                              className="hidden lg:flex border-2 border-r-0 rounded-l border-zinc-100 items-center  pl-12 py-0.5"
                            />
                          )}
                          {i === 0 && (
                            <EquivalentInventory
                              value={
                                storeSettings?.separate_floor_inventory === true
                                  ? (firstRow.floor_inventory_qty ?? 0)
                                  : (firstRow.shelf_floor_size ?? 0)
                              }
                              colisage={firstRow.colisage ?? 1}
                              unit={firstRow.unit ?? ""}
                              useKgPce={storeSettings?.use_kg_pce === true}
                              className="hidden lg:flex border-2 border-x-0 border-zinc-100 items-center justify-center py-0.5"
                            />
                          )}
                          {i === 0 && (
                            <EquivalentInventory
                              value={reference.quantity_actual ?? 0}
                              colisage={reference.colisage ?? 1}
                              unit={reference.unit ?? ""}
                              useKgPce={storeSettings?.use_kg_pce === true}
                              className="hidden lg:flex border-2 border-l-0 rounded-r border-zinc-100 items-center justify-center py-0.5"
                            />
                          )}
                        </>
                      ) : (
                        <>
                          <p className="inline lg:hidden text-xs text-zinc-500 font-bold text-center">
                            LINÉAIRE
                          </p>
                          <ShelfFloorSize
                            mercurialeId={reference.mercuriale_id!}
                            unit={reference.unit ?? ""}
                            value={
                              i > 0
                                ? shelfFloorSizeEquivalent /
                                  (reference.colisage ?? 1)
                                : (reference.shelf_floor_size ?? 0)
                            }
                            updateInventory={updateInventory}
                            minimal
                            className="lg:px-4"
                            isNewReference={false}
                            floorInventoryQuantity={0}
                          />
                          {i === 0 && (
                            <EquivalentInventory
                              value={firstRow.shelf_floor_size ?? 0}
                              colisage={firstRow.colisage ?? 1}
                              unit={firstRow.unit ?? ""}
                              useKgPce={storeSettings?.use_kg_pce === true}
                              className="hidden lg:flex border-2 rounded border-zinc-100 items-center justify-center py-0.5"
                            />
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>

                {sortedReferences.length > 1 && (
                  <hr className="last:hidden lg:hidden my-2" />
                )}
              </Fragment>
            )
          })}
        </div>
        <div className="hidden lg:block w-12 my-1">
          {storeSettings?.view_local && (
            <LocalFlagToggle firstRow={firstRow} storeId={storeId} />
          )}
        </div>
      </div>
    </>
  )
}
