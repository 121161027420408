import OrderedQuantityCell from "./OrderedQuantityCell"
import { useDispatch, useSelector } from "react-redux"
import Description from "./Description"
import SearchBar from "./SearchBar"
import SortIcon from "./SortIcon"
import TotalDisplay from "./TotalDisplay"
import { GroupedVirtuoso } from "react-virtuoso"
import { DispatchActionType, StateType } from "../../types"
import { useMemo, useRef } from "react"
import { GroupContent } from "./GroupContent"
import { RecapSortOption } from "../../reducers/userInterfaceReducer"
import { getUnit } from "../../utils/getUnit"
import { AllMercurialInfo } from "../../reducers/mercurialReducer"
import { GetOrderItem } from "../../utils/__generated__/graphql"
import { alphabeticalSort, numericalSort } from "../../utils/sort"
import { computeQuantityActual } from "../../utils/computeInventoriesValues"

export interface GroupedReferences {
  supplierName: string
  supplierId: string
  products: (AllMercurialInfo | GetOrderItem)[]
}

interface TableProps {
  references: (AllMercurialInfo | GetOrderItem)[]
  excessiveProducts: (AllMercurialInfo | GetOrderItem)[]
  tooLowProducts: (AllMercurialInfo | GetOrderItem)[]
}

const Table = ({
  references,
  excessiveProducts,
  tooLowProducts,
}: TableProps) => {
  const dispatch = useDispatch<DispatchActionType>()

  const { storeCurrency, storeSettings, storeStoreSuppliers } = useSelector(
    (state: StateType) => state.storeReducer,
  )
  const online = useSelector(
    (state: StateType) => state.connectionReducer.online,
  )

  const { sortOption, sortOrder, searchTerm } = useSelector(
    (state: StateType) => state.userInterfaceReducer.recapPage,
  )

  // Keep sort list and option to keep order when updating data
  const oldSortState = useRef<{
    list: string[]
    option: RecapSortOption
    order: "asc" | "desc"
  } | null>(null)

  const columns = useMemo(() => {
    return [
      {
        id: RecapSortOption.MercurialeName,
        name: "Nom",
        nameInMobile: "Nom",
      },
      {
        id: RecapSortOption.Colisage,
        name: "Colisage",
        nameInMobile: "Colisage",
      },
      {
        id: RecapSortOption.PV,
        name: "Prix de vente (TTC)",
        nameInMobile: "PV",
      },
      {
        id: RecapSortOption.QuantityActual,
        name: "Colis commandés",
        nameInMobile: "Colis",
      },
      {
        id: RecapSortOption.PA,
        name: "Coût total (HT)",
        nameInMobile: "PA",
      },
      {
        id: RecapSortOption.PotentialRevenue,
        name: "CA potentiel (TTC)",
        nameInMobile: "CA",
      },
    ]
  }, [])

  const handleSortChange = (columnName: (typeof columns)[number]["id"]) => {
    if (columnName === sortOption) {
      dispatch({
        type: "setSortOption",
        payload: {
          page: "recapPage",
          sortOption: columnName,
          sortOrder: sortOrder === "asc" ? "desc" : "asc",
        },
      })
      return
    }
    dispatch({
      type: "setSortOption",
      payload: {
        page: "recapPage",
        sortOption: columnName,
        sortOrder: "asc",
      },
    })
  }

  const Column = ({ id, name, nameInMobile }: (typeof columns)[number]) => {
    const hideInMobile = ["pv", "colisage"].includes(id)

    return (
      <button
        onClick={() => handleSortChange(id)}
        className={`${
          hideInMobile ? "hidden md:block" : ""
        } col-span-auto text-xs xl:text-sm font-medium text-gray-500 uppercase tracking-wider h-full`}
      >
        <p className="flex justify-center items-center gap-2 h-full">
          <span className="w-full lg:w-[95%] text-center">
            <span className="hidden md:block truncate">{name}</span>
            <span className="block md:hidden truncate">{nameInMobile}</span>
          </span>
          <span className={`hidden w-[5%] cursor-pointer lg:block`}>
            <SortIcon
              column={id}
              sortedColumn={sortOption}
              sortOrder={sortOrder}
            />
          </span>
        </p>
      </button>
    )
  }

  // Filter and sort logic
  const filteredReferences = useMemo<Partial<AllMercurialInfo>[]>(() => {
    const _references = [...references]
    let _sortedMercurialeInfos: Partial<AllMercurialInfo>[] = []

    if (
      oldSortState.current?.option === sortOption &&
      oldSortState.current?.order === sortOrder &&
      oldSortState.current?.list !== undefined
    ) {
      _sortedMercurialeInfos = oldSortState.current?.list
        .map((mercurialeId) => {
          const mercurialeInfo = _references.find(
            (_filteredMercurialeInfo) =>
              _filteredMercurialeInfo.mercuriale_id === mercurialeId,
          )
          return mercurialeInfo
        })
        .filter(
          (references): references is AllMercurialInfo | GetOrderItem =>
            references !== undefined,
        )
    } else if (_references.length > 0) {
      _sortedMercurialeInfos = _references.sort((a, b) => {
        const quantityA =
          storeSettings?.use_kg_pce === true
            ? (a.quantity_actual ?? 0) / (a.colisage ?? 1)
            : (a.quantity_actual ?? 0)
        const quantityB =
          storeSettings?.use_kg_pce === true
            ? (b.quantity_actual ?? 0) / (b.colisage ?? 1)
            : (b.quantity_actual ?? 0)
        switch (sortOption) {
          case RecapSortOption.MercurialeName:
            return alphabeticalSort(
              a.mercuriale_name ?? "",
              b.mercuriale_name ?? "",
              sortOrder,
            )
          case RecapSortOption.Colisage:
            return numericalSort(a.colisage ?? 1, b.colisage ?? 1, sortOrder)
          case RecapSortOption.PV:
            return numericalSort(a.pv ?? 0, b.pv ?? 0, sortOrder)
          case RecapSortOption.QuantityActual:
            return numericalSort(quantityA, quantityB, sortOrder)
          case RecapSortOption.PA:
            return numericalSort(
              computeQuantityActual(a, storeSettings?.use_kg_pce) * (a.pa ?? 0),
              computeQuantityActual(b, storeSettings?.use_kg_pce) * (b.pa ?? 0),
              sortOrder,
            )
          case RecapSortOption.PotentialRevenue:
            return numericalSort(
              computeQuantityActual(a, storeSettings?.use_kg_pce) * (a.pv ?? 0),
              computeQuantityActual(b, storeSettings?.use_kg_pce) * (b.pv ?? 0),
              sortOrder,
            )
          default:
            return numericalSort(quantityA, quantityB, "desc")
        }
      })
      // Save sort option and sort order to refs
      oldSortState.current = {
        option: sortOption ?? RecapSortOption.QuantityActual,
        order: sortOrder,
        list: _sortedMercurialeInfos.map(
          (_sortedMercurialeInfo) => _sortedMercurialeInfo.mercuriale_id ?? "",
        ),
      }
    }
    return _sortedMercurialeInfos.filter(
      (mercurialeInfo) =>
        // Search bar to filter by name
        searchTerm === "" ||
        mercurialeInfo.mercuriale_name
          ?.toLowerCase()
          .includes(searchTerm.toLowerCase()),
    )
  }, [references, searchTerm, sortOption, sortOrder, storeSettings?.use_kg_pce])

  // Convert list of references to list groupedReferencesed by suppliers for virtuoso
  const suppliersData = useMemo(() => {
    return filteredReferences.reduce<GroupedReferences[]>(
      (suppliersData, reference) => {
        const supplierDataIndex = suppliersData.findIndex(
          (supplierData) =>
            supplierData.supplierName === reference.supplier_name,
        )
        if (supplierDataIndex === -1) {
          suppliersData.push({
            supplierName: reference.supplier_name ?? "",
            supplierId: reference.supplier_id ?? "",
            products: [reference],
          })
          return suppliersData
        }
        suppliersData[supplierDataIndex].products.push(reference)
        return suppliersData
      },
      [],
    )
  }, [filteredReferences])

  // Group counts for virtuoso
  const groupCounts = useMemo(() => {
    const data = suppliersData.map(({ products }) => products.length)
    return data
  }, [suppliersData])

  // TODO : stock color in config tailwind
  return (
    <div className="rounded shadow h-full flex flex-col">
      <SearchBar />
      <div className="flex flex-col h-full">
        <div className="flex flex-1 flex-col min-h-0">
          <div
            className={`grid grid-cols-4 md:grid-cols-6 gap-1 lg:gap-4 p-2 content-center items-center sticky top-0 z-10 bg-white backdrop-blur backdrop-filter py-2 md:py-4`}
          >
            {columns.map((column) => (
              <Column
                key={column.id}
                id={column.id}
                name={column.name}
                nameInMobile={column.nameInMobile}
              />
            ))}
          </div>
          {suppliersData.length > 0 && groupCounts.length > 0 && (
            <GroupedVirtuoso
              className="h-[88%] lg:h-[92%] "
              style={{ height: "100%" }}
              groupCounts={groupCounts}
              groupContent={(groupIndex) => (
                <GroupContent
                  supplierData={suppliersData[groupIndex]}
                  storeCurrency={storeCurrency}
                  storeStoreSuppliers={storeStoreSuppliers}
                />
              )}
              itemContent={(index, groupIndex) => {
                const supplier = suppliersData[groupIndex]
                if (!supplier) return null
                const localIndex =
                  index -
                  groupCounts.slice(0, groupIndex).reduce((a, c) => a + c, 0)
                const product = supplier.products[localIndex]
                if (!product) return null
                const quantityActualInUnit =
                  storeSettings?.use_kg_pce === true
                    ? (product.quantity_actual ?? 0)
                    : (product.quantity_actual ?? 0) * (product.colisage ?? 1)
                const isQuantityExcessive = excessiveProducts.some(
                  (excessiveProduct) =>
                    excessiveProduct.mercuriale_id === product.mercuriale_id,
                )
                const isQuantityTooLow = tooLowProducts.some(
                  (tooLowProduct) =>
                    tooLowProduct.mercuriale_id === product.mercuriale_id,
                )
                const isLastItem =
                  localIndex === suppliersData[groupIndex].products.length - 1
                return (
                  <div
                    className={`grid grid-cols-4 md:grid-cols-6 items-center h-full gap-1 lg:gap-4 p-2 text-base lg:text-lg ${
                      isLastItem ? "mb-4" : ""
                    } ${"active" in product && product.active === false ? "opacity-20" : isQuantityExcessive || isQuantityTooLow ? "rounded my-1 bg-red-30" : ""}`}
                  >
                    <div className="col-span-1">
                      <Description product={product} isOnline={online} />
                    </div>
                    <div className="col-span-1 hidden md:flex gap-4 justify-center items-center">
                      <p>
                        {typeof product.colisage === "number"
                          ? `${product.colisage} ${getUnit(product.unit)}`
                          : "-"}
                      </p>
                    </div>
                    <div className="col-span-1 hidden md:flex justify-center items-center">
                      <p>
                        {typeof product.pv === "number"
                          ? new Intl.NumberFormat("fr-FR", {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 2,
                              style: "currency",
                              currency: storeCurrency ?? "EUR",
                            }).format(product.pv)
                          : "-"}
                      </p>
                    </div>
                    <div className="col-span-1 block">
                      <OrderedQuantityCell
                        handleQuantity={true}
                        product={product}
                        storeSettings={storeSettings}
                      />
                    </div>
                    <div className="col-span-1 flex justify-center items-center">
                      <p>
                        {typeof product.pa === "number"
                          ? new Intl.NumberFormat("fr-FR", {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 2,
                              style: "currency",
                              currency: storeCurrency ?? "EUR",
                            }).format(product.pa * quantityActualInUnit)
                          : "-"}
                      </p>
                    </div>
                    <div className="col-span-1 flex justify-center items-center">
                      <p>
                        {typeof product.pv === "number"
                          ? new Intl.NumberFormat("fr-FR", {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 2,
                              style: "currency",
                              currency: storeCurrency ?? "EUR",
                            }).format(product.pv * quantityActualInUnit)
                          : "-"}
                      </p>
                    </div>
                  </div>
                )
              }}
            />
          )}
        </div>
        <TotalDisplay storeCurrency={storeCurrency} references={references} />
      </div>
    </div>
  )
}

export default Table
