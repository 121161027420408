import { createSelector } from "@reduxjs/toolkit"
import { StateType } from "../types"
import { MercurialReducerState } from "../reducers/mercurialReducer"

export function mercurialeStateSelector(state: StateType) {
  return state.mercurialReducer
}

export const filteredMercurialeReducerSelector = createSelector(
  [mercurialeStateSelector],
  (mercurialeState) => {
    const selectedDimMercurialeid = mercurialeState.selectedDimMercurialeId
    if (selectedDimMercurialeid === undefined) {
      return mercurialeState
    }
    const filteredMercurialeInfos =
      mercurialeState.mercurialAndStoreInventories.filter(
        (mercurialAndStoreInventory) =>
          mercurialAndStoreInventory.dim_mercuriale_id ===
          selectedDimMercurialeid,
      )
    const filteredUpdatedReferences = Object.values(
      mercurialeState.updatedReferences,
    ).reduce<MercurialReducerState["updatedReferences"]>(
      (updatedReferences, updatedReference) => {
        if (
          filteredMercurialeInfos.some(
            (filteredMercurialeInfo) =>
              filteredMercurialeInfo.mercuriale_id ===
              updatedReference.mercurialeId,
          )
        ) {
          updatedReferences[updatedReference.mercurialeId] = updatedReference
        }
        return updatedReferences
      },
      {},
    )

    return {
      ...mercurialeState,
      mercurialAndStoreInventories: filteredMercurialeInfos,
      updatedReferences: filteredUpdatedReferences,
    }
  },
)

export const selectedRankingsSelector = createSelector(
  [mercurialeStateSelector],
  (mercurialeState) => {
    const selectedDimMercurialeId = mercurialeState.selectedDimMercurialeId
    if (selectedDimMercurialeId === undefined) {
      return undefined
    }

    return mercurialeState.rankings[selectedDimMercurialeId] ?? undefined
  },
)

export const selectedMercurialeInfosSelector = createSelector(
  [filteredMercurialeReducerSelector],
  (mercurialeState) => {
    return mercurialeState.mercurialAndStoreInventories.map(
      (mercurialeInfo) => {
        const updatedReference =
          mercurialeState.updatedReferences[mercurialeInfo.mercuriale_id!]
        if (updatedReference === undefined) return mercurialeInfo
        return {
          ...mercurialeInfo,
          back_inventory_qty:
            updatedReference.backInventoryQuantity ??
            mercurialeInfo.back_inventory_qty,
          floor_inventory_qty:
            updatedReference.floorInventoryQuantity ??
            mercurialeInfo.floor_inventory_qty,
          shelf_inventory_qty:
            updatedReference.shelfFloorSize ?? mercurialeInfo.shelf_floor_size,
          quantity_actual:
            updatedReference.orderInventoryQuantity ??
            mercurialeInfo.quantity_actual,
          stock_too_high_flag:
            updatedReference?.stock_too_high_flag ??
            mercurialeInfo.stock_too_high_flag,
          stock_too_low_flag:
            updatedReference?.stock_too_low_flag ??
            mercurialeInfo.stock_too_low_flag,
          stock_to_verify_flag: mercurialeInfo.stock_to_verify_flag, // Be careful, we this value is not updated
        }
      },
    )
  },
)
