import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { AlertTriangleIcon } from "../AlertTriangle"
import { DispatchActionType, type StateType } from "../../types"
import { Button } from "../../ui/Button"
import { usePotentialRevenue } from "../../hooks/usePotentialRevenue"
import { Spinner } from "../../ui/Spinner"
import { WifiAlertIcon } from "../../ui/AlertIcons"
import { Modal } from "../../ui/Modal"
import { ArrowPathIcon, CloudIcon } from "@heroicons/react/24/outline"

interface ConfirmRecapModalProps {
  setOpen: (open: boolean) => void
  open: boolean
  isLoading: boolean
  handleConfirm: (isWaitingSend: boolean) => void
  isWaitingSend: boolean
  setIsWaitingSend: (isWaitingSend: boolean) => void
}

const ConfirmRecapModal = ({
  setOpen,
  open,
  isLoading,
  handleConfirm,
  isWaitingSend,
  setIsWaitingSend,
}: ConfirmRecapModalProps): JSX.Element => {
  const dispatch = useDispatch<DispatchActionType>()
  const [isHealthLoading, setIsHealthLoading] = useState(false)

  const { storeCurrency } = useSelector(
    (state: StateType) => state.storeReducer,
  )
  const { enable } = useSelector(
    (state: StateType) => state.trainingModeReducer,
  )
  const online = useSelector(
    (state: StateType) => state.connectionReducer.online,
  )

  const { totalOrders, orderPrice } = usePotentialRevenue()

  async function retryConnection() {
    try {
      setIsHealthLoading(true)
      const response = await fetch(
        import.meta.env.VITE_API_URL.replace("/graphql/", "/health"),
      )
      if (!response.ok) {
        throw new Error(`Response status: ${response.status}`)
      }
      const data = (await response.json()) as { status: string }
      if (data.status !== "ok") {
        throw new Error(`Response status: ${data.status}`)
      }
      dispatch({
        type: "setOnline",
        payload: true,
      })
      dispatch({
        type: "setSnackbar",
        payload: {
          type: "success",
          message: "Connexion internet rétablie",
          icon: <CloudIcon className="w-6 h-6" />,
        },
      })
    } catch (error) {
      console.error(error)
      dispatch({
        type: "setSnackbar",
        payload: {
          type: "error",
          message: "Aucune connexion internet",
          icon: <CloudIcon className="w-6 h-6" />,
        },
      })
    } finally {
      setIsHealthLoading(false)
    }
  }

  function handleClose() {
    if (isLoading || isHealthLoading) return

    setTimeout(() => {
      setIsWaitingSend(false)
    }, 250) // to avoid seeing the false value while closing window
    setOpen(false)
  }

  const title = isWaitingSend
    ? "Vous n'êtes pas connecté à internet."
    : "Voulez-vous vraiment envoyer cette commande ?"

  const icon = isWaitingSend ? <WifiAlertIcon /> : <AlertTriangleIcon />

  return (
    <Modal
      title={title}
      icon={icon}
      open={open}
      onClose={handleClose}
      hideCloseButton
      className="max-w-md rounded-2xl"
    >
      {isWaitingSend ? (
        <>
          <p className="text-[#667085] text-[14px] font-normal text-center">
            La commande sera envoyée automatiquement une fois la connexion
            rétablie.
          </p>
          <div className="flex items-center gap-3 mt-8 justify-center">
            {!isLoading && !isHealthLoading ? (
              <div className="w-full flex flex-col justify-center items-center gap-3">
                <button
                  onClick={retryConnection}
                  className="w-fit flex justify-center items-center gap-2"
                >
                  <ArrowPathIcon className="w-5 h-5" />
                  Réessayer de se connecter
                </button>
                <Button
                  onClick={handleClose}
                  className="w-full h-[44px]"
                  color="ghost"
                >
                  Annuler
                </Button>
              </div>
            ) : (
              <Spinner />
            )}
          </div>
        </>
      ) : (
        <>
          <p className="text-[#667085] text-[14px] font-normal text-center">
            Coût total -{" "}
            {new Intl.NumberFormat("fr-FR", {
              minimumFractionDigits: 0,
              maximumFractionDigits: 2,
              style: "currency",
              currency: storeCurrency ?? "EUR",
            }).format(orderPrice ?? 0)}
            <br />
            Nb de caisses total -{" "}
            {new Intl.NumberFormat("fr-FR", {
              maximumFractionDigits: 0,
            }).format(totalOrders ?? 0)}
          </p>
          <div className="flex items-center gap-3 mt-8 justify-center">
            <Button
              onClick={handleClose}
              className="w-full h-[44px] text-[16px]"
              color="ghost"
              disabled={isLoading}
            >
              Annuler
            </Button>
            <Button
              color={enable ? "yellow" : "green"}
              onClick={() => {
                void handleConfirm(!online)
              }}
              className="w-full h-[44px] text-[16px]"
              loading={isLoading}
            >
              Envoyer
            </Button>
          </div>
        </>
      )}
    </Modal>
  )
}

export default ConfirmRecapModal
