import { Fragment, useEffect, useState } from "react"
import { AllMercurialInfo } from "../../reducers/mercurialReducer"
import { getOrderQuantity } from "../../utils/getOrderQuantity"
import { twJoin } from "tailwind-merge"
import { OrderQuantityButton } from "../../components/button/OrderQuantityButton"
import { Button } from "../../ui/Button"
import { StoreSettings } from "../../utils/__generated__/graphql"
import Description from "../../components/Table/Description"
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/outline"

interface CheckoutModalProps {
  tooLowProducts: AllMercurialInfo[]
  excessiveProducts: AllMercurialInfo[]
  boxProducts: AllMercurialInfo[]
  allItems: AllMercurialInfo[]
  handleClose: () => void
  handleConfirm: () => void
  storeSettings: StoreSettings
}

export function CheckoutModal({
  tooLowProducts,
  excessiveProducts,
  boxProducts,
  allItems,
  handleClose,
  handleConfirm,
  storeSettings,
}: CheckoutModalProps) {
  const [tooLowProductIds, setTooLowProductIds] = useState<string[]>()
  const [excessiveProductIds, setExcessiveProductIds] = useState<string[]>()
  const [boxProductIds, setBoxProductIds] = useState<string[]>()
  const [isAtBottom, setIsAtBottom] = useState(false)

  useEffect(() => {
    if (tooLowProductIds === undefined) {
      setTooLowProductIds(tooLowProducts.map((item) => item.mercuriale_id!))
    }
  }, [tooLowProductIds, tooLowProducts])

  useEffect(() => {
    if (excessiveProductIds === undefined) {
      setExcessiveProductIds(
        excessiveProducts.map((item) => item.mercuriale_id!),
      )
    }
  }, [excessiveProductIds, excessiveProducts])

  useEffect(() => {
    if (boxProductIds === undefined) {
      setBoxProductIds(boxProducts.map((item) => item.mercuriale_id!))
    }
  }, [boxProductIds, boxProducts])

  return (
    <div className="flex-col justify-start items-start gap-4 flex h-full overflow-auto">
      <h1 className="text-center text-black text-2xl font-bold leading-tight">
        Alertes IDA
      </h1>
      <hr className="self-stretch  border border-[#dddddd]" />
      <div className="relative flex-1 overflow-auto w-full">
        <div
          className="h-full overflow-y-auto flex flex-col gap-2 w-full"
          onScroll={(event) => {
            const target = event.target as HTMLDivElement
            setIsAtBottom(
              target.scrollTop >= target.scrollHeight - target.clientHeight,
            )
          }}
        >
          {(tooLowProductIds ?? []).length > 0 && (
            <div className="flex flex-col gap-2 w-full">
              <div
                className={twJoin(
                  "bg-neutral-50 flex-col justify-start text-left items-start gap-2 flex border-l-4 pl-4 py-2 sticky top-0",
                  tooLowProducts.length > 0
                    ? " border-red-700"
                    : " border-green-700",
                )}
              >
                {" "}
                <p className="self-stretch items-center inline-flex text-black text-2xl font-bold leading-tight">
                  Risques de rupture : {tooLowProducts.length}
                </p>
                <p className="self-stretch justify-start items-start text-left text-[#4e4e4e] text-sm font-normal leading-none">
                  Attention selon nos données ces quantités semblent trop
                  faibles
                </p>
              </div>
              <div className="flex w-full self-stretch py-2 px-1 md:px-2 bg-white rounded-sm border border-[#f4f4f4] justify-center items-center flex-col">
                <div className="grid grid-cols-4 w-full pb-2">
                  <div />
                  <div />
                  <p className="text-[#006f0f] text-sm font-black leading-tight ">
                    IDA
                  </p>
                  <p className="text-[#006f0f] text-sm font-black leading-tight">
                    VOUS
                  </p>
                </div>
                <div className="grid grid-cols-4 w-full">
                  {tooLowProductIds?.map((item, index) => {
                    const product = allItems.find(
                      (allItem) => item === allItem.mercuriale_id,
                    )
                    if (product === undefined) return <Fragment />
                    return (
                      <Fragment key={index}>
                        <div className="flex items-center">
                          <Description product={product} isOnline={true} />
                        </div>
                        <div className="min-w-full flex items-center justify-end">
                          {tooLowProducts.some(
                            (allItem) =>
                              product.mercuriale_id === allItem.mercuriale_id,
                          ) ? (
                            <ExclamationCircleIcon className="w-6 h-6 md:w-8 md:h-8 text-red-900" />
                          ) : (
                            <CheckCircleIcon className="w-6 h-6 md:w-8 md:h-8 text-green-900" />
                          )}
                        </div>
                        <p className="text-black text-xs sm:text-sm font-bold items-center justify-center flex">
                          {getOrderQuantity({
                            backQuantity: product.back_inventory_qty ?? 0,
                            floorQuantity: product.floor_inventory_qty ?? 0,
                            predictedQuantityArray:
                              product.quantity_predicted_array ?? [],
                            storeSettings: storeSettings,
                          })}{" "}
                          cs
                        </p>

                        <div className="bg-white justify-center items-center   flex">
                          <div
                            className={twJoin(
                              "h-8 lg:h-10 p-1 rounded-3xl justify-between items-center flex gap-1",
                            )}
                          >
                            <OrderQuantityButton
                              type="DECREMENT_QUANTITY"
                              product={product}
                            />
                            <div
                              className={twJoin(
                                " text-xs font-bold sm:text-sm w-6 sm:w-10",
                                tooLowProducts.some(
                                  (allItem) =>
                                    product.mercuriale_id ===
                                    allItem.mercuriale_id,
                                )
                                  ? "text-red-900"
                                  : "text-green-950",
                              )}
                            >
                              {product.quantity_actual} cs
                            </div>
                            <OrderQuantityButton
                              type="INCREMENT_QUANTITY"
                              product={product}
                            />
                          </div>
                        </div>
                      </Fragment>
                    )
                  })}
                </div>
              </div>
            </div>
          )}
          {(excessiveProductIds ?? []).length > 0 && (
            <div className="flex flex-col gap-2 w-full">
              <div
                className={twJoin(
                  "bg-neutral-50 flex-col justify-start text-left items-start gap-2 flex border-l-4 pl-4 py-2 sticky top-0",
                  excessiveProducts.length > 0
                    ? " border-red-700"
                    : " border-green-700",
                )}
              >
                {" "}
                <p className="self-stretch  items-center inline-flex text-black text-2xl font-bold leading-tight">
                  Quantités excessives : {excessiveProducts.length}
                </p>
                <p className="self-stretch justify-start items-start text-left text-[#4e4e4e] text-sm font-normal leading-none">
                  Attention ces quantités risquent d&apos;augmenter la démarque
                </p>
              </div>
              <div className="flex w-full self-stretch py-2 px-1 md:px-2 bg-white rounded-sm border border-[#f4f4f4] justify-center items-center flex-col">
                <div className="grid grid-cols-4 w-full pb-2">
                  <div />
                  <div />
                  <p className="text-[#006f0f] text-sm font-black leading-tight ">
                    IDA
                  </p>
                  <p className="text-[#006f0f] text-sm font-black leading-tight">
                    VOUS
                  </p>
                </div>
                <div className="grid grid-cols-4 w-full">
                  {excessiveProductIds?.map((item, index) => {
                    const product = allItems.find(
                      (allItem) => item === allItem.mercuriale_id,
                    )
                    if (product === undefined) return <Fragment />
                    return (
                      <Fragment key={index}>
                        <div className="flex items-center">
                          <Description product={product} isOnline={true} />
                        </div>
                        <div className="min-w-full flex items-center justify-end">
                          {excessiveProducts.some(
                            (allItem) =>
                              product.mercuriale_id === allItem.mercuriale_id,
                          ) ? (
                            <ExclamationCircleIcon className="w-6 h-6 md:w-8 md:h-8 text-red-900" />
                          ) : (
                            <CheckCircleIcon className="w-6 h-6 md:w-8 md:h-8 text-green-900" />
                          )}
                        </div>
                        <p className="text-black text-xs sm:text-sm font-bold items-center justify-center flex">
                          {getOrderQuantity({
                            backQuantity: product.back_inventory_qty ?? 0,
                            floorQuantity: product.floor_inventory_qty ?? 0,
                            predictedQuantityArray:
                              product.quantity_predicted_array ?? [],
                            storeSettings: storeSettings,
                          })}{" "}
                          cs
                        </p>

                        <div className="bg-white justify-center items-center   flex">
                          <div
                            className={twJoin(
                              "h-8 lg:h-10 p-1 rounded-3xl justify-between items-center flex gap-1",
                            )}
                          >
                            <OrderQuantityButton
                              type="DECREMENT_QUANTITY"
                              product={product}
                            />
                            <div
                              className={twJoin(
                                " text-xs font-bold sm:text-sm w-6 sm:w-10",
                                excessiveProducts.some(
                                  (allItem) =>
                                    product.mercuriale_id ===
                                    allItem.mercuriale_id,
                                )
                                  ? "text-red-900"
                                  : "text-green-950",
                              )}
                            >
                              {product.quantity_actual} cs
                            </div>
                            <OrderQuantityButton
                              type="INCREMENT_QUANTITY"
                              product={product}
                            />
                          </div>
                        </div>
                      </Fragment>
                    )
                  })}
                </div>
              </div>
            </div>
          )}
          {(boxProductIds ?? []).length > 0 && (
            <div className="flex flex-col gap-2 w-full">
              <div
                className={twJoin(
                  "bg-neutral-50 flex-col justify-start text-left items-start gap-2 flex border-l-4 pl-4 py-2 sticky top-0",
                  boxProducts.length > 0
                    ? " border-red-700"
                    : " border-green-700",
                )}
              >
                {" "}
                <p className="self-stretch  items-center inline-flex text-black text-2xl font-bold leading-tight">
                  Produits en box : {boxProducts.length}
                </p>
                <p className="self-stretch justify-start items-start text-left text-[#4e4e4e] text-sm font-normal leading-none">
                  Attention ces références sont commandées par box
                </p>
              </div>
              <div className="flex w-full self-stretch py-2 px-1 md:px-2 bg-white rounded-sm border border-[#f4f4f4] justify-center items-center flex-col">
                <div className="grid grid-cols-2 w-full max-h-64 overflow-y-auto gap-y-0 overflow-x-visible">
                  {boxProductIds?.map((item, index) => {
                    const product = allItems.find(
                      (allItem) => item === allItem.mercuriale_id,
                    )
                    if (product === undefined) return <Fragment />
                    return (
                      <Fragment key={index}>
                        <div className="flex items-center">
                          <Description product={product} isOnline={true} />
                        </div>

                        <div className="bg-white justify-center items-center   flex">
                          <div
                            className={twJoin(
                              "h-8 lg:h-10 p-1 rounded-3xl justify-between items-center flex gap-1",
                            )}
                          >
                            <OrderQuantityButton
                              type="DECREMENT_QUANTITY"
                              product={product}
                            />
                            <div
                              className={twJoin(
                                " text-xs font-bold sm:text-sm w-6 sm:w-10",
                                boxProducts.some(
                                  (allItem) =>
                                    product.mercuriale_id ===
                                    allItem.mercuriale_id,
                                )
                                  ? "text-red-900"
                                  : "text-green-950",
                              )}
                            >
                              {product.quantity_actual} cs
                            </div>
                            <OrderQuantityButton
                              type="INCREMENT_QUANTITY"
                              product={product}
                            />
                          </div>
                        </div>
                      </Fragment>
                    )
                  })}
                </div>
              </div>
            </div>
          )}
        </div>
        {!isAtBottom && (
          <div className="absolute bottom-0 left-0 w-full h-6 bg-gradient-to-b from-transparent to-neutral-200" />
        )}
      </div>
      <div className="flex self-stretch items-center gap-3 justify-center">
        <Button
          onClick={handleClose}
          className="w-full h-[44px] text-[16px]"
          color={
            (tooLowProducts.length === 0 && excessiveProducts.length) === 0
              ? "ghost"
              : "darkGreen"
          }
        >
          Annuler
        </Button>
        <Button
          onClick={() => {
            handleConfirm()
          }}
          className="w-full h-[44px] text-[16px]"
          color={
            tooLowProducts.length === 0 && excessiveProducts.length === 0
              ? "darkGreen"
              : "ghost"
          }
        >
          Valider
        </Button>
      </div>
    </div>
  )
}
