import { AllMercurialInfo } from "../../reducers/mercurialReducer"
import { Modal } from "../../ui/Modal"
import { StoreSettings } from "../../utils/__generated__/graphql"
import { CheckoutModal } from "./CheckoutModal"

interface OrderCorrectionModalProps {
  open: boolean
  setOpen: (value: boolean) => void
  handleConfirm: () => void
  tooLowProducts: AllMercurialInfo[]
  excessiveProducts: AllMercurialInfo[]
  allItems: AllMercurialInfo[]
  boxProducts: AllMercurialInfo[]
  storeSettings: StoreSettings
}

export function OrderCorrectionModal({
  open,
  setOpen,
  handleConfirm,
  tooLowProducts,
  excessiveProducts,
  allItems,
  boxProducts,
  storeSettings,
}: OrderCorrectionModalProps) {
  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false)
      }}
      hideCloseButton
      border="solid"
      fullScreen
      className="max-w-full"
    >
      <CheckoutModal
        allItems={allItems}
        boxProducts={boxProducts}
        tooLowProducts={tooLowProducts}
        excessiveProducts={excessiveProducts}
        storeSettings={storeSettings}
        handleClose={() => {
          setOpen(false)
        }}
        handleConfirm={handleConfirm}
      />
    </Modal>
  )
}
