import { GroupedReferences } from "."
import { StoreReducerState } from "../../reducers/storeReducer"
import { OrderQuantityButton } from "../button/OrderQuantityButton"

interface TableCellProps {
  handleQuantity?: boolean
  product: GroupedReferences["products"][number]
  storeSettings: StoreReducerState["storeSettings"]
}

const OrderedQuantityCell = ({
  handleQuantity,
  product,
  storeSettings,
}: TableCellProps) => {
  return (
    <div className="flex gap-1 md:gap-4 justify-center items-center md:text-xl">
      {handleQuantity && "active" in product && (
        <OrderQuantityButton type="DECREMENT_QUANTITY" product={product} />
      )}
      <p>
        <span className="w-full">
          <span className="w-1/2 text-right">
            {storeSettings?.use_kg_pce === true
              ? (product.quantity_actual ?? 0) / (product.colisage ?? 1)
              : (product.quantity_actual ?? 0)}
          </span>
          <span className="ml-1 w-1/2">cs</span>
        </span>
      </p>
      {handleQuantity && "active" in product && (
        <OrderQuantityButton type="INCREMENT_QUANTITY" product={product} />
      )}
    </div>
  )
}

export default OrderedQuantityCell
